// extracted by mini-css-extract-plugin
export var featuredProjects = "external-projects-module--featured-projects--078d9";
export var moreProjects = "external-projects-module--more-projects--10ca4";
export var moreProjectsHeaderSection = "external-projects-module--more-projects-header-section--21ffc";
export var moreProjectsHeaderSectionTitle = "external-projects-module--more-projects-header-section-title--076a5";
export var moreProjectsMoreLink = "external-projects-module--more-projects-more-link--6c4b7";
export var projectListItem = "external-projects-module--project-list-item--4ecc1";
export var projectListingItemDescription = "external-projects-module--project-listing-item-description--8d5d7";
export var projectListingItemImage = "external-projects-module--project-listing-item-image--87bec";
export var projectListingItemName = "external-projects-module--project-listing-item-name--7254e";
export var viewMoreProjectsButton = "external-projects-module--view-more-projects-button--87615";